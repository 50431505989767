import React from 'react'

import PageHeader from "./PageHeader";

const Customers = () => {
  return (
    <div>
      <PageHeader name='Customers' />
    </div>
  )
}

export default Customers
