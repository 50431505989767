import React from 'react'
import PageHeader from "./PageHeader";

const Line = () => {
  return (
    <div>
        <PageHeader name='Line' />
    </div>
  )
}

export default Line
