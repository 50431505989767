import React from 'react'

import PageHeader from "./PageHeader";

const Orders = () => {
  return (
    <div>
      <PageHeader name='Orders' />
    </div>
  )
}

export default Orders
