import ImageOne from '../Img/green-cnc.png'
import ImageTwo from '../Img/grey-cnc.png'
import ImageThree from '../Img/red-cnc.png'
import ImageFour from '../Img/yellow-cnc.png'

export const state = [
  {
    id: 1,
    groupType: "Group 1",
    machines: [
      {
        id: 1,
        machineAvailability: "Green",
        name:'Machine1',
        color: "#00FF00",
        img:ImageOne,
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Current Program Comment", value: "XYZ Comment" },  
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Current Cycle Time", value: "12:00" },
          { id: 1, keyName: "Feed Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
      {
        id: 2,
        machineAvailability: "Red",
        name:'Machine2',
        color: "#FF0000",
        img:ImageThree,
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Cycle Time", value: "80%" },
          { id: 1, keyName: "Feet Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
      {
        id: 3,
        machineAvailability: "Grey",
        name:'Machine3',
        color: "grey",
        img:ImageTwo,
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Cycle Time", value: "80%" },
          { id: 1, keyName: "Feet Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
      {
        id: 4,
        machineAvailability: "Yellow",
        name:'Machine4',
        img:ImageFour,
        color: "#FFFF00",
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Cycle Time", value: "80%" },
          { id: 1, keyName: "Feet Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
    ],
  },
  {
    id: 2,
    groupType: "Group 2",
    machines: [
      {
        id: 1,
        machineAvailability: "Green",
         name:'Machine1',
        color: "#00FF00",
        img:ImageOne,
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Cycle Time", value: "80%" },
          { id: 1, keyName: "Feet Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
      {
        id: 2,
        machineAvailability: "Red",
         name:'Machine2',
        color: "#FF0000",
        img:ImageThree,
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Cycle Time", value: "80%" },
          { id: 1, keyName: "Feet Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
      {
        id: 3,
        machineAvailability: "Grey",
         name:'Machine3',
        color: "grey",
        img:ImageTwo,
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Cycle Time", value: "80%" },
          { id: 1, keyName: "Feet Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
      {
        id: 4,
        machineAvailability: "Yellow",
         name:'Machine4',
        color: "#FFFF00",
        img:ImageFour,
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Cycle Time", value: "80%" },
          { id: 1, keyName: "Feet Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
    ],
  },
  {
    id: 3,
    groupType: "Group 3",
    machines: [
      {
        id: 1,
        machineAvailability: "Green",
         name:'Machine1',
        color: "#00FF00",
        img:ImageOne,
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Cycle Time", value: "80%" },
          { id: 1, keyName: "Feet Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
      {
        id: 2,
        machineAvailability: "Red",
         name:'Machine2',
        color: "#FF0000",
        img:ImageThree,
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Cycle Time", value: "80%" },
          { id: 1, keyName: "Feet Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
      {
        id: 3,
        machineAvailability: "Grey",
         name:'Machine3',
        color: "grey",
        img:ImageTwo,
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Cycle Time", value: "80%" },
          { id: 1, keyName: "Feet Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
      {
        id: 4,
        machineAvailability: "Yellow",
         name:'Machine4',
        color: "#FFFF00",
        img:ImageFour,
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Cycle Time", value: "80%" },
          { id: 1, keyName: "Feet Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
    ],
  },
  {
    id: 4,
    groupType: "Group 4",
    machines: [
      {
        id: 1,
        machineAvailability: "Green",
         name:'Machine1',
        color: "#00FF00",
        img:ImageOne,
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Cycle Time", value: "80%" },
          { id: 1, keyName: "Feet Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
      {
        id: 2,
        machineAvailability: "Red",
         name:'Machine2',
        color: "#FF0000",
        img:ImageThree,
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Cycle Time", value: "80%" },
          { id: 1, keyName: "Feet Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
      {
        id: 3,
        machineAvailability: "Grey",
         name:'Machine3',
        color: "grey",
        img:ImageTwo,
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Cycle Time", value: "80%" },
          { id: 1, keyName: "Feet Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
      {
        id: 4,
        machineAvailability: "Yellow",
        name:'Machine4',
        color: "#FFFF00",
        img:ImageFour,
        general_Info: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Company Name", value: "XYZ Name" },
          { id: 1, keyName: "Machine Model", value: "80%" },
          { id: 1, keyName: "Machine Make", value: "80%" },
          { id: 1, keyName: "Purchase Year", value: "80%" },
          { id: 1, keyName: "Ip Address", value: "80%" },
          { id: 1, keyName: "CNC Control Model", value: "80%" },
          { id: 1, keyName: "No of Axis", value: "80%" },
        ],
        load_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Maximum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Minimum Load", value: "80%(x) 70%(y) 65%(z)" },
          { id: 1, keyName: "Spindle Load", value: "80%" },
        ],
        production_Data: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Current Program Name", value: "XYZ Name" },
          { id: 1, keyName: "Current Program Number", value: "787" },
          { id: 1, keyName: "Projected Part Count", value: "80%" },
          { id: 1, keyName: "Actual Part Count", value: "80%" },
          { id: 1, keyName: "Cycle Time", value: "80%" },
          { id: 1, keyName: "Feet Rate", value: "80%" },
          { id: 1, keyName: "Spindle RPM", value: "80%" },
        ],
        alarm_Trends: [
          { id: 1, keyName: "Specifications", value: "Time" },
          { id: 1, keyName: "Emergency Stop", value: "06:00" },
          { id: 1, keyName: "Hydrolic not on", value: "07:00" },
          { id: 1, keyName: "Tool not clamp", value: "08:00" },
        ],
        efficiency: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Total Power on Time", value: "80%" },
          { id: 1, keyName: "Total Operaing time", value: "80%" },
          { id: 1, keyName: "Total Ideal Time", value: "80%" },
          { id: 1, keyName: "Total Efficiency", value: "80%" },
        ],
        offset_Change_Record: [
          { id: 1, keyName: "Specifications", value: "Values" },
          { id: 1, keyName: "Offset Change Max Value", value: "80%" },
          { id: 1, keyName: "Offset Change Min Value", value: "80%" },
          { id: 1, keyName: "Offset Change Current Value", value: "80%" },
        ],
      },
    ],
  },
];
export const dataLink=state[0].machines[0]
