import React from 'react'

import PageHeader from "./PageHeader";

const Employees = () => {
  return (
    <div>
        <PageHeader name='Employees' />
    </div>
  )
}

export default Employees
